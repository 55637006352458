import AmlHashForm from "aml-hash-form";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// includes
import StatusBar from "elements/statusBar";
import { api, common, types } from "helpers";
import FieldSelect from "elements/fieldSelect";

function Form() {
  // props
  const { id } = useParams();
  const navigate = useNavigate();
  // state
  const [status, setStatus] = useState(id ? "process" : "success");
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState("");
  const [permission, setPermission] = useState(common.getPermission());
  const [permissionList, setPermissionList] = useState([]);
  const [effectField, setEffectField] = useState([]);
  const [finder, setFinder] = useState({
    permissionRoleId: true,
    agentIds: true,
  });

  // validate
  const formFields = {
    firstName: { validate: ["req"] },
    lastName: {},
    email: { validate: ["req", "email"] },
    password: {
      validate: ["req", "min:8", "max:15"],
      value: common.generateString(8),
    },
    agentIds: {
      validate: [],
    },
    permissionRoleId: { validate: ["req"] },
  };
  const {
    fields,
    errors,
    handleChange,
    handleSubmit,
    setValue,
    setMultiValue,
  } = AmlHashForm(formFields);

  // effect
  useEffect(() => {
    if (id) {
      getInfo();
    }
    getPermission(null);
  }, []);

  useEffect(() => {
    if (effectField.length > 0) {
      setValue(effectField[0], effectField[1]);
      setFinder((prev) => ({ ...prev, [effectField[0]]: false }));
    }
  }, [effectField]);

  // api
  const getInfo = () => {
    let data = {
      url: "users",
      method: "GET",
      query: `/${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let result = res.data;
        //getPermission(result.permissionRoleId);
        setMultiValue({
          email: result.email,
          password: common.generateString(8),
          firstName: result.firstName,
          lastName: result.lastName,
          agentIds: result.agentIds || [],
          permissionRoleId: result.permissionRoleId,
        });
        setPermission(result.permissions);
        setStatus("success");
      } else {
        setStatus("error");
      }
    });
  };

  const getPermission = (ids) => {
    let data = {
      url: "permission",
      method: "GET",
      query: "?offset=0&limit=1000",
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let result = res.data.data;
        setPermissionList(result);
        setStatus("success");
        if (ids) {
          let filterData =
            result.length > 0 && result.filter((item) => item._id === ids);
          if (filterData) {
            setPermission(filterData[0].permissions);
          }
        }
      } else {
        setStatus("error");
      }
    });
  };

  // handler
  const handleCheckChange = (e) => {
    const { checked, value } = e.target;
    setPermission((prev) => {
      let nData = [...prev];
      checked ? nData.push(value) : nData.splice(nData.indexOf(value), 1);
      return nData;
    });
  };

  const onSubmit = () => {
    let valid = handleSubmit();
    if (valid) {
      setLoading(true);
      let data = {};
      let params = { ...fields };
      if (id) {
        delete params.password;
        if (fields.agentIds?.length > 0) {
          var filtered = fields.agentIds.map(function (el) {
            return el.value;
          });
          params.agentIds = filtered;
        } else {
          params.agentIds = [];
        }
        data = {
          url: "users",
          method: "PUT",
          query: `/${id}`,
          body: {
            ...params,
            permissionRoleId: fields.permissionRoleId?.value,
            permissions: permission,
          },
        };
      } else {
        if (fields.agentIds?.length > 0) {
          var filtered = fields.agentIds.map(function (el) {
            return el.value;
          });
          params.agentIds = filtered;
        } else {
          params.agentIds = [];
        }
        data = {
          url: "users",
          method: "POST",
          body: {
            ...params,
            permissionRoleId: fields.permissionRoleId?.value,
            permissions: permission,
          },
        };
      }
      // return
      api.call(data, (res) => {
        if (res.status === 200) {
          common.notify(
            "S",
            `User has been ${!id ? "created" : "updated"} successfully`
          );
          navigate("/users");
        } else if (res.status === 500) {
          setError("Email address already exist");
        } else {
          common.notify("E", "Something went wrong");
        }
        setLoading(false);
      });
    }
  };

  const permissionChange = (data) => {
    let filterData =
      permissionList &&
      permissionList?.length > 0 &&
      permissionList.filter((item) => item._id == data.value);
    if (filterData) {
      setPermission(filterData[0].permissions);
    }
  };

  // render
  return (
    <Fragment>
      <div className="title-wrap d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <img
            src={common.loadImg("back-icn.svg")}
            className="ed-cp"
            alt=""
            onClick={() => navigate("/users")}
          />
          <h1 className="page-title ps-4">{id ? "Edit" : "Add New"} User</h1>
        </div>
      </div>
      <div className="content-wrap">
        {status !== "success" ? (
          <StatusBar status={status} />
        ) : (
          <div className="container-fluid">
            <h2 className="form-title">User Info</h2>
            <div className="form-wrap">
              <div className="row">
                <div className="col-md-6 mb-4">
                  <label>First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    placeholder="Enter the first name"
                    className="form-control"
                    value={fields.firstName}
                    onChange={handleChange}
                  />
                  <p className="error-txt">{errors.firstName}</p>
                </div>
                <div className="col-md-6 mb-4">
                  <label>Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    placeholder="Enter the last name"
                    className="form-control"
                    value={fields.lastName}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6 mb-4">
                  <label>Email Address</label>
                  <input
                    type="text"
                    name="email"
                    placeholder="Enter the email"
                    className="form-control"
                    value={fields.email}
                    onChange={(e) => {
                      handleChange(e);
                      setError("");
                    }}
                  />
                  <p className="error-txt">{errors.email || error}</p>
                </div>
                {!id && (
                  <div className="col-md-6 mb-4">
                    <label>Password</label>
                    <input
                      type="text"
                      name="password"
                      placeholder="Enter the password"
                      className="form-control"
                      value={fields.password}
                      onChange={handleChange}
                    />
                    <p className="error-txt">{errors.password}</p>
                  </div>
                )}
                <div className="col-md-6 mb-4">
                  <label>Agent</label>
                  {/* <p className="et-field-value"> */}
                  <FieldSelect
                    api="agent"
                    query='?offset=0&limit=1000&query={"isActive":true,"isVerified":true}'
                    get="businessName"
                    set="_id"
                    value={fields.agentIds}
                    placeholder="Select the agent"
                    render={"yes"}
                    clearable={false}
                    finder={finder.agentIds}
                    multi={true}
                    onSelect={(data) => {
                      setEffectField(["agentIds", data]);
                    }}
                  />
                  {/* </p> */}
                </div>
                <div className="col-md-6 mb-4">
                  <label>Roles</label>
                  {/* <p className="et-field-value"> */}
                  <FieldSelect
                    api="permission"
                    query='?offset=0&limit=1000&query={"isActive":true}'
                    get="name"
                    set="_id"
                    value={fields.permissionRoleId}
                    placeholder="Select the permission"
                    render={"yes"}
                    clearable={false}
                    finder={finder.permissionRoleId}
                    multi={false}
                    onSelect={(data) => {
                      setEffectField(["permissionRoleId", data]);
                      permissionChange(data);
                    }}
                  />
                  <p className="error-txt">{errors.permissionRoleId}</p>
                  {/* </p> */}
                </div>
              </div>
            </div>
            <h2 className="form-title">Permissions</h2>
            <div className="form-wrap">
              {types.permission.list.map((i, k) => (
                <div key={k}>
                  <label className="fw-bold">
                    {types.permission.label[i.key]}
                  </label>
                  <ul className="row permissions-wrap pt-2">
                    {i.types.map((m, n) => (
                      <li className="col-md-3" key={n}>
                        <label className="role-checkbox-wrap">
                          <input
                            type="checkbox"
                            name="read"
                            value={i.key + m}
                            checked={permission?.includes(i.key + m)}
                            onChange={handleCheckChange}
                          />
                          <span className="checkmark"></span>
                          {types.permission.label[m]}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
            <div className="modal-footer">
              <button
                className="btn ed-btn-default ed-btn-save-dt"
                onClick={onSubmit}
                disabled={loading}
              >
                {loading ? "Processing..." : "Save & Update"}
              </button>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}
export default Form;
